import React from "react";
import classNames from "classnames";
import { m } from "framer-motion";
import { Container, Text } from "@atoms";

const FeatureColumns = ({ features }) => {
  const container = {
    hidden: {
      opacity: 0,
      transition: {
        when: "afterChildren",
      },
    },
    show: {
      opacity: 1,
      transition: {
        when: "beforeChildren",
        staggerChildren: 0.05,
      },
    },
  };

  const item = {
    hidden: {
      opacity: 0,
      y: "50%",
      transition: {
        type: "tween",
        duration: 0.5,
      },
    },
    show: {
      opacity: 1,
      y: "0%",
      transition: {
        type: "tween",
        duration: 0.5,
      },
    },
  };

  return (
    <Container>
      <m.ul
        variants={container}
        initial="hidden"
        whileInView="show"
        className="flex flex-wrap justify-center gap-10"
      >
        {features?.map(feat => {
          const { uid, icon, color, heading, descriptor } = feat;
          return (
            <m.li
              key={uid}
              className="flex flex-[1_1_100%] flex-col items-center gap-5 text-center sm:flex-[0_1_calc(50%-1.25rem)] md:flex-[0_1_calc(33.333%-1.67rem)]"
            >
              {!!icon && (
                <m.div
                  variants={item}
                  className={classNames(
                    "svg-icon flex h-12 w-12 items-center justify-center",
                    {
                      "text-teal": color === "teal" || !color,
                      "text-purple": color === "purple",
                      "text-pink": color === "pink",
                    }
                  )}
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{ __html: icon[0]?.code }}
                />
              )}
              <Text variant="h6" className="whitespace-pre-line">
                {heading}
              </Text>
              <Text variant="xs">{descriptor}</Text>
            </m.li>
          );
        })}
      </m.ul>
    </Container>
  );
};

FeatureColumns.defaultProps = {};

export default FeatureColumns;
